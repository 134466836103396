import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { Card, CardContent, Typography, Grid, CircularProgress, Select, MenuItem } from "@mui/material";
import CommonClasses from "Styles/Common.module.css";
import { BarChart } from "@mui/icons-material";
import dashboardClasses from './Dashboard.module.css';
import { Services } from "Services";
import { FaGifts } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";
import CountUp from 'react-countup';
import { FaUsers } from "react-icons/fa";


function Dashboard() {
    const token = { Authorization: `token ${localStorage.getItem('token')}` };

    // States for each card's data and loading
    const [totalSalesData, setTotalSalesData] = useState({});
    const [genifastSalesData, setGenifastSalesData] = useState(null);
    const [medbuzzSalesData, setMedbuzzSalesData] = useState(null);
    const [usersData, setUsersData] = useState(null); // New state for users

    const [loadingTotalSales, setLoadingTotalSales] = useState(true);
    const [loadingGenifastSales, setLoadingGenifastSales] = useState(true);
    const [loadingMedbuzzSales, setLoadingMedbuzzSales] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true); // New loading state for users

    const [totalSalesFilter, setTotalSalesFilter] = useState("ALL");
    const [genifastSalesFilter, setGenifastSalesFilter] = useState("ALL");
    const [medbuzzSalesFilter, setMedbuzzSalesFilter] = useState("ALL");

    // Fetch data for a specific card
    const fetchDashboardData = async (type, filter, setData, setLoading) => {
        setLoading(true);
        const body = {
            Type: type,
            Filter: filter
        };

        try {
            const response = await Services.getDashboardData("POST", JSON.stringify(body), token);
            setData(response);
        } catch (error) {
            console.error(`Error fetching data for ${type}:`, error);
        } finally {
            setLoading(false);
        }
    };

    // Use effects for each card to fetch data when its filter changes
    useEffect(() => {
        fetchDashboardData("SALES", totalSalesFilter, setTotalSalesData, setLoadingTotalSales);
    }, [totalSalesFilter]);

    useEffect(() => {
        fetchDashboardData("GIFTS", genifastSalesFilter, setGenifastSalesData, setLoadingGenifastSales);
    }, [genifastSalesFilter]);

    useEffect(() => {
        fetchDashboardData("EVENTS", medbuzzSalesFilter, setMedbuzzSalesData, setLoadingMedbuzzSales);
    }, [medbuzzSalesFilter]);

    // Fetch users without any filter
    useEffect(() => {
        fetchDashboardData("USERS", null, setUsersData, setLoadingUsers);
    }, []); // No filter for users

    return (
        <Layout Active={"Dashboard"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div>
                    <h1 style={{ color: '#FB3E7A' }}>DASHBOARD</h1>
                </div>

                <Grid container spacing={3}>

                    {/* Total Sales Card */}
                    <Grid item xs={12} sm={6}>
                        <Card className={dashboardClasses['card-dash-main']}>
                            <CardContent>
                                <div className={dashboardClasses['select-dash-main-cont']}>
                                    <Select
                                        className={dashboardClasses['select-dash-cont']}
                                        value={totalSalesFilter}
                                        onChange={(event) => setTotalSalesFilter(event.target.value)}
                                    >
                                        <MenuItem value="TODAY">Today</MenuItem>
                                        <MenuItem value="CURRENT_MONTH">This Month</MenuItem>
                                        <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                                        <MenuItem value="ALL">All</MenuItem>
                                    </Select>
                                </div>
                                <Grid className={dashboardClasses['grid-card']} container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography className={dashboardClasses['grid-card-one']} variant="h6" component="div">Total Sales</Typography>
                                        {loadingTotalSales ? (
                                            <CircularProgress />
                                        ) : (
                                            <Typography className={dashboardClasses['grid-card-two']} variant="h4" component="div">
                                                ₹ <CountUp start={0} end={totalSalesData?.Sales || 0} duration={2} />
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <BarChart style={{ fontSize: 40, color: '#f5b14c', padding: '10px', borderRadius: '50%', background: '#DEC79B' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Genifast Sales Card */}
                    <Grid item xs={12} sm={6}>
                        <Card className={dashboardClasses['card-dash-main']}>
                            <CardContent>
                                <div className={dashboardClasses['select-dash-main-cont']}>
                                    <Select
                                        className={dashboardClasses['select-dash-cont']}
                                        value={genifastSalesFilter}
                                        onChange={(event) => setGenifastSalesFilter(event.target.value)}
                                    >
                                        <MenuItem value="TODAY">Today</MenuItem>
                                        <MenuItem value="CURRENT_MONTH">This Month</MenuItem>
                                        <MenuItem value="DELIVERED">Delivered</MenuItem>
                                        <MenuItem value="ALL">All</MenuItem>
                                    </Select>
                                </div>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography className={dashboardClasses['grid-card-one']} variant="h6" component="div">Total Gifts</Typography>
                                        {loadingGenifastSales ? (
                                            <CircularProgress />
                                        ) : (
                                            <Typography className={dashboardClasses['grid-card-two']} variant="h4" component="div">
                                                <CountUp start={0} end={genifastSalesData?.Gifts || 0} duration={2} />
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <FaGifts style={{ fontSize: 40, color: '#f5b14c', padding: '10px', borderRadius: '50%', background: '#DEC79B' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Medbuzz Sales Card */}
                    <Grid item xs={12} sm={6}>
                        <Card className={dashboardClasses['card-dash-main']}>
                            <CardContent>
                                <div className={dashboardClasses['select-dash-main-cont']}>
                                    <Select
                                        className={dashboardClasses['select-dash-cont']}
                                        value={medbuzzSalesFilter}
                                        onChange={(event) => setMedbuzzSalesFilter(event.target.value)}
                                    >
                                        <MenuItem value="TODAY">Today</MenuItem>
                                        <MenuItem value="CURRENT_MONTH">This Month</MenuItem>
                                        <MenuItem value="ALL">All</MenuItem>
                                    </Select>
                                </div>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography className={dashboardClasses['grid-card-one']} variant="h6" component="div">Total Events</Typography>
                                        {loadingMedbuzzSales ? (
                                            <CircularProgress />
                                        ) : (
                                            <Typography className={dashboardClasses['grid-card-two']} variant="h4" component="div">
                                                <CountUp start={0} end={medbuzzSalesData?.Events || 0} duration={2} />
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <GiPartyPopper style={{ fontSize: 40, color: '#f5b14c', padding: '10px', borderRadius: '50%', background: '#DEC79B' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Users Card */}
                    <Grid item xs={12} sm={6}>
                        <Card className={dashboardClasses['card-dash-main']}>
                            <div className={dashboardClasses['users-card-dash']}>
                                <h1>USERS</h1>
                            </div>
                            <CardContent>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography className={dashboardClasses['grid-card-one']} variant="h6" component="div">Total Users</Typography>
                                        {loadingUsers ? (
                                            <CircularProgress />
                                        ) : (
                                            <Typography className={dashboardClasses['grid-card-two']} variant="h4" component="div">
                                                <CountUp start={0} end={usersData?.Users || 0} duration={2} />
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <FaUsers style={{ fontSize: 40, color: '#f5b14c', padding: '10px', borderRadius: '50%', background: '#DEC79B' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
        </Layout>
    );
}

export default Dashboard;
