import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import UserClasses from '../users/Users.module.css';

function Coupons() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [CouponCodes, setCouponCodes] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [searchType, setSearchType] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchCoupons = () => {
        setLoading(true);
        Services.getCoupons("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setCouponCodes(response.CouponCodes);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchCoupons();
    }, []);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredCoupons = CouponCodes.filter((item) => {
        return (
            item.CouponCode.toLowerCase().includes(searchString.toLowerCase()) &&
            (searchType === "" || item.CouponType === parseInt(searchType))
        );
    });

    return (
        <Layout Active={"Coupons"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Coupons</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div>
                    <h1 style={{ color: '#FB3E7A' }}>COUPONS</h1>
                </div>
                <div className={CommonClasses["control-btns"]}>
                    <div className={UserClasses["search-container"]} style={{ display: 'flex', gap: '10px', width: '320px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
                            <FaSearch className={UserClasses["search-icon"]} />
                            <input
                                type="text"
                                placeholder="Search Coupon By Code"
                                style={{ textIndent: '15px', width: '250px' }}
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                                className={UserClasses["search-btn"]}
                            />
                        </div>
                        <select
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                            className={UserClasses["search-btn"]}
                        >
                            <option value="">All Types</option>
                            <option value="1">Percentage</option>
                            <option value="2">Amount</option>
                        </select>
                    </div>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/addcoupon");
                        }}
                    >
                        <BiPlus size={20} /> Create New Coupon
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Coupon Code", "Offer Value", "Start Date", "End Date", "Coupon Type", "Coupon Status", "Actions"]}
                        />
                    ) : filteredCoupons.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Coupon Code</th>
                                        <th>Offer Value</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Coupon Type</th>
                                        <th>Coupon Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCoupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                        <tr
                                            key={item.CouponCodeID}
                                            id={item.CouponCodeID}
                                            name={item.CouponCode}>

                                            <td>{item.CouponCode}</td>
                                            <td>{item.OfferValue}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.EndDate}
                                                </Moment>
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                {item.CouponType === 1 ? (
                                                    <div className={CommonClasses["CouponType-active"]}>
                                                        <span>Percentage</span>
                                                    </div>
                                                ) : (
                                                    <div className={CommonClasses["CouponType-inactive"]}>
                                                        <span>Amount</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td style={{ padding: '10px' }}>
                                                {item.Status === 1 ? (
                                                    <div className={CommonClasses["Status-active"]}>
                                                        <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div className={CommonClasses["Status-inactive"]}>
                                                        <span>Inactive</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div className={CommonClasses["Action-btns"]}>
                                                    <button
                                                        onClick={() => navigate(`/updatecoupon/${item.CouponCodeID}`, { state: { coupon: item } })}
                                                    >
                                                        <FaRegEdit />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={filteredCoupons.length}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[5, 10, 25]}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>No Coupon Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Coupons;
