import React, { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { FaRegEdit, FaSearch, FaCheckCircle, FaHourglassStart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import debounce from "lodash/debounce";
import { TablePagination } from "@mui/material";
import UserClasses from '../users/Users.module.css';

function Events() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [activeStatus, setActiveStatus] = useState(2);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchEvents = (status = 1) => {
        setLoading(true);
        const body = JSON.stringify({ EventStatus: status });
        Services.listEvents("POST", body, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Events);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    const searchForEvents = useCallback(
        debounce((searchString, status = 1) => {
            setLoading(true);
            const body = JSON.stringify({
                SearchString: searchString,
                EventStatus: status,
            });
            Services.listEvents("POST", body, token)
                .then((response) => {
                    setLoading(false);
                    if (response.Status === 1) {
                        const filteredEvents = response.Events.filter(
                            event =>
                                event.EventName.toLowerCase().includes(searchString.toLowerCase()) ||
                                event.UserName.toLowerCase().includes(searchString.toLowerCase())
                        );
                        setData(filteredEvents);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                });
        }, 300),
        []
    );

    useEffect(() => {
        fetchEvents(activeStatus);
    }, [activeStatus]);

    useEffect(() => {
        if (searchString) {
            searchForEvents(searchString, activeStatus);
        } else {
            fetchEvents(activeStatus);
        }
    }, [searchString, searchForEvents, activeStatus]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const currentData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Layout Active={"Events"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Events</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div>
                    <h1 style={{ color: '#FB3E7A' }}>EVENTS</h1>
                </div>
                <div className={CommonClasses["control-btns"]}>
                    <div className={UserClasses["search-container"]} style={{ width: '300px' }}>
                        <FaSearch className={UserClasses["search-icon"]} />
                        <input
                            type="text"
                            placeholder="Search Events By Event Name | User Name"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            className={UserClasses["search-btn"]}
                        />
                    </div>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    <div className={CommonClasses["table-control-btns"]}>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 2 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(2)}
                        >
                            <FaHourglassStart /> Active Events
                        </div>
                        <div
                            className={`${CommonClasses["button"]} ${activeStatus === 1 ? CommonClasses["active"] : ""}`}
                            onClick={() => setActiveStatus(1)}
                        >
                            <FaCheckCircle /> Completed Events
                        </div>
                    </div>
                    {loading ? (
                        <div className={CommonClasses["loading"]}>Loading...</div>
                    ) : (
                        <div className={`${CommonClasses["Table"]} ${CommonClasses["fade-in"]}`}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>EventImages</th>
                                        <th>EventName</th>
                                        <th>UserName</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.length > 0 ? (
                                        currentData.map((item) => (
                                            <tr
                                                key={item.EventID}
                                                id={item.EventID}
                                                name={item.EventName}
                                                onClick={() => navigate(`/eventdetails/${item.EventID}`)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <td>
                                                    <img
                                                        style={{ width: "10rem", height: "5rem", objectFit: "fill", borderRadius: '5px' }}
                                                        src={item.EventTemplate}
                                                        alt="event-img"
                                                        onClick={() => {
                                                            window.open(item.EventTemplate, "_blank");
                                                        }}
                                                    />
                                                </td>
                                                <td>{item.EventName}</td>
                                                <td>{item.UserName}</td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.UpdatedAt}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: 'center' }}>No Events Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={data.length}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[5, 10, 25]}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Events;
