
import uuid from "react-uuid";

import { IoIosFolder } from 'react-icons/io'
import { FaUsers } from "react-icons/fa";
import { HiSquare2Stack } from "react-icons/hi2";
import { TbPackageExport } from "react-icons/tb";
import { GiPartyPopper } from "react-icons/gi";
import { FaBoxOpen } from "react-icons/fa";
import { FaGifts } from "react-icons/fa";
import { HiTemplate } from "react-icons/hi";
import { MdCircleNotifications, MdDashboard } from "react-icons/md";



export const menuItems = [

  {
    id: uuid(),
    multiple: false,
    name: "Dashboard",
    menuLink: "/dashboard",
    active: "Dashboard",
    icon: MdDashboard,
  
   },

 {
    id: uuid(),
    multiple: false,
    name: "Users",
    menuLink: "/users",
    active: "Users",
    icon: FaUsers,
  
   },

   {
      id: uuid(),
      multiple: false,
      name: "Banners",
      menuLink: "/banners",
      active: "Banners",
      icon: HiSquare2Stack,
    
     },

     {
      id: uuid(),
      multiple: false,
      name: "Categories",
      menuLink: "/categories",
      active: "Categories",
      icon: IoIosFolder,
    
     },

     {
      id: uuid(),
      multiple: false,
      name: "Gifts",
      menuLink: "/gifts",
      active: "Gifts",
      icon: FaGifts,
    
     },
    

     {
      id: uuid(),
      multiple: false,
      name: "Events",
      menuLink: "/events",
      active: "Events",
      icon: GiPartyPopper,
    
     },

     {
      id: uuid(),
      multiple: false,
      name: "Coupons",
      menuLink: "/coupons",
      active: "Coupons",
      icon: FaGifts,
    
     },

     {
      id: uuid(),
      multiple: false,
      name: "Bulk Notifications",
      menuLink: "/bulknotifications",
      active: "BulkNotifications",
      icon: MdCircleNotifications,
    
     },

     {
      id: uuid(),
      multiple: false,
      name: "Orders",
      menuLink: "/orders",
      active: "Orders",
      icon: FaBoxOpen,
    
     },

   //   {
   //    id: uuid(),
   //    multiple: false,
   //    name: "Packages",
   //    menuLink: "/packages",
   //    active: "Packages",
   //    icon: TbPackageExport,
    
   //   },

     {
      id: uuid(),
      multiple: false,
      name: "Templates",
      menuLink: "/templates",
      active: "Templates",
      icon: HiTemplate,
    
     },

];

