import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import UserClasses from '../users/Users.module.css';

function BulkNotifications() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchGifts = () => {
        setLoading(true);
        Services.getBulkNotifications("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Notifications);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchGifts();
    }, []);

    // Reset page to 0 when search string changes
    useEffect(() => {
        setPage(0);
    }, [searchString]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter(item =>
        item.Title.toLowerCase().includes(searchString.toLowerCase())
    );

    const slicedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Layout Active={"BulkNotifications"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gifts</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div>
                    <h1 style={{ color: '#FB3E7A' }}>BULK NOTIFICATIONS</h1>
                </div>
                <div className={CommonClasses["control-btns"]}>
                    <div className={UserClasses["search-container"]} style={{ width: '200px' }}>
                        <FaSearch className={UserClasses["search-icon"]} />
                        <input
                            type="text"
                            placeholder="Search Notifications By Title"
                            // style={{ textIndent: '15px' }}
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            className={UserClasses["search-btn"]}
                        />
                    </div>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/createbulknotifications");
                        }}
                    >
                        <BiPlus size={20} /> Create New Notification
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Notification ID", "Title", "Message", "CreatedAt"]}
                        />
                    ) : slicedData.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Notification ID</th>
                                        <th>Title</th>
                                        <th>Message</th>
                                        <th>CreatedAt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slicedData.map((item) => (
                                        <tr
                                            key={item.NotificationID}
                                            id={item.NotificationID}
                                            name={item.Title}
                                        >
                                            <td>{item.NotificationID}</td>
                                            <td>{item.Title}</td>
                                            <td>{item.Message}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <TablePagination
                                component="div"
                                count={filteredData.length}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleRowsPerPageChange}
                                rowsPerPageOptions={[5, 10, 25]}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>No Notifications Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default BulkNotifications;
